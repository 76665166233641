
	import { defineComponent, ref, onMounted } from 'vue';
	import { io, Socket } from 'socket.io-client';
	import Peer from 'peerjs';
	import SentryLogger from '@/utils/SentryLogger';
	import { serverUrl, peerServerUrl, peerServerPort } from '@/utils/envs';

	interface IPeers {
		[key: string]: Peer.MediaConnection | undefined;
	}
	interface IRoomParameter {
		roomId?: string;
		videoInputLabel?: string;
		audioInputLabel?: string;
		streamAudio?: string;
	}

	interface IMediaDeviceInfo extends MediaDeviceInfo {
		getCapabilities?(): MediaTrackCapabilities;
	}

	interface IDevice {
		selected?: IMediaDeviceInfo;
		list?: IMediaDeviceInfo[];
	}

	interface IDevices {
		cameras: IDevice;
		microphones: IDevice;
	}

	export default defineComponent({
		name: 'Robot',
		setup() {
			const DEFAULT_CAMERA_CONSTRAINTS = {
				width: {
					min: 320,
					ideal: 1920,
					max: 1920,
				},
				height: {
					min: 180,
					ideal: 1080,
					max: 1080,
				},
				frameRate: {
					min: 1,
					ideal: 20,
					max: 30,
				},
				aspectRatio: 1.7777777777777777,
				facingMode: 'environment',
			};
			const sentryLogger = new SentryLogger();
			let socket: Socket;
			let myStream: MediaStream;
			let myPeer: Peer;
			const peers: IPeers = {};
			let roomParameters: IRoomParameter = {
				roomId: '',
				videoInputLabel: 'default',
				audioInputLabel: 'default',
				streamAudio: 'false',
			};
			const devices: IDevices = {
				cameras: {},
				microphones: {},
			};

			onMounted(async () => {
				roomParameters = { ...roomParameters, ...getRoomParameters() };
				await loadMediaDevices();
				await startStreaming();
			});

			function getRoomParameters() {
				const pairs = window.location.search.substring(1).split('&');
				const urlParameters: { [key: string]: string; roomId: string } = {
					roomId: '',
				};

				pairs.forEach((pair) => {
					if (pair === '') return;
					const query = pair.split('=');
					const key = decodeURIComponent(query[0]) as string;
					urlParameters[key] = decodeURIComponent(query[1]);
				});
				urlParameters.roomId = window.location.pathname.split('/').pop() || '';
				return urlParameters;
			}

			async function loadMediaDevices() {
				try {
					const isTextSimilar = (text1: string, text2: string) => {
						const text1lc = text1?.toLowerCase();
						const text2lc = text2?.toLowerCase();
						return text1lc.includes(text2lc) || text2lc.includes(text1lc);
					};

					const mediaDevices = await navigator.mediaDevices.enumerateDevices();
					devices.cameras.list = mediaDevices.filter((device) => device.kind === 'videoinput');
					devices.microphones.list = mediaDevices.filter((device) => device.kind === 'audioinput');

					const cameraLabels = devices.cameras.list.map((device) => device.label);
					const microphoneLabels = devices.microphones.list.map((device) => device.label);
					sentryLogger.info(`Cameras found: ${cameraLabels.join(', ')}`);
					sentryLogger.info(`Microphones found: ${microphoneLabels.join(', ')}`);

					devices.cameras.selected = devices.cameras.list.find((device) => {
						if (roomParameters.videoInputLabel?.trim()) {
							return isTextSimilar(device.label, roomParameters.videoInputLabel);
						} else {
							return isTextSimilar(device.label, 'default');
						}
					});
					devices.microphones.selected = devices.microphones.list.find((device) => {
						if (roomParameters.audioInputLabel?.trim()) {
							return isTextSimilar(device.label, roomParameters.audioInputLabel);
						} else {
							return isTextSimilar(device.label, 'default');
						}
					});

					sentryLogger.info(`Camera selected: ${devices.cameras.selected?.label}`);
					sentryLogger.info(`Microphone selected: ${devices.microphones.selected?.label}`);
				} catch (error) {
					sentryLogger.error(`Error loading media devices: ${(error as DOMException).message}`);
					sentryLogger.info('Restarting in 5 seconds');
					setTimeout(() => {
						hangup();
						window.location.reload();
					}, 5000);
				}
			}

			async function startStreaming() {
				sentryLogger.debug('Initializing media devices');
				const constraints: MediaStreamConstraints = {};

				let selectedCameraCapabilities;
				if (typeof devices.cameras.selected?.getCapabilities === 'function') {
					selectedCameraCapabilities = devices.cameras.selected.getCapabilities();
				}
				sentryLogger.debug(`Selected camera capabilities: ${JSON.stringify(selectedCameraCapabilities, null, 2)}`);

				constraints.video = {
					deviceId: devices.cameras.selected?.deviceId,
					width: {
						min: selectedCameraCapabilities?.width?.min || DEFAULT_CAMERA_CONSTRAINTS.width.min,
						ideal: selectedCameraCapabilities?.width?.max || DEFAULT_CAMERA_CONSTRAINTS.width.ideal,
						max: selectedCameraCapabilities?.width?.max || DEFAULT_CAMERA_CONSTRAINTS.width.max,
					},
					height: {
						min: selectedCameraCapabilities?.height?.min || DEFAULT_CAMERA_CONSTRAINTS.height.min,
						ideal: selectedCameraCapabilities?.height?.max || DEFAULT_CAMERA_CONSTRAINTS.height.ideal,
						max: selectedCameraCapabilities?.height?.max || DEFAULT_CAMERA_CONSTRAINTS.height.max,
					},
					frameRate: {
						min: selectedCameraCapabilities?.frameRate?.min || DEFAULT_CAMERA_CONSTRAINTS.frameRate.min,
						ideal: DEFAULT_CAMERA_CONSTRAINTS.frameRate.ideal,
						max: selectedCameraCapabilities?.frameRate?.max || DEFAULT_CAMERA_CONSTRAINTS.frameRate.max,
					},
					aspectRatio:
						selectedCameraCapabilities?.width?.max && selectedCameraCapabilities?.height?.max
							? selectedCameraCapabilities.width.max / selectedCameraCapabilities.height.max
							: DEFAULT_CAMERA_CONSTRAINTS.aspectRatio,
					facingMode: DEFAULT_CAMERA_CONSTRAINTS.facingMode,
				};
				if (roomParameters.streamAudio === 'true') {
					constraints.audio = {
						deviceId: devices.microphones.selected?.deviceId,
						echoCancellation: true,
						noiseSuppression: true,
						autoGainControl: true,
					};
				}

				try {
					myStream = await navigator.mediaDevices.getUserMedia(constraints);
					initPeerAndSocketEvents();
					sentryLogger.debug('Stream initialized');
				} catch (error) {
					sentryLogger.error(`Error while initializing media device: ${(error as DOMException).message}`);
					sentryLogger.info('Restarting in 5 seconds');
					setTimeout(() => {
						hangup();
						window.location.reload();
					}, 5000);
				}
			}

			function initPeerAndSocketEvents() {
				sentryLogger.info('Starting telepresence peer and sockets');
				try {
					myPeer = new Peer(undefined, {
						host: peerServerUrl,
						port: peerServerPort,
						path: '/myapp/',
						config: {
							iceServers: [
								{
									urls: ['stun:us-turn8.xirsys.com'],
								},
								{
									username:
										'1gzEdlKRFFrrp4PoehmL-L6-aVc16tX5OLY7Ia3rjPYx_owZK5D1XLeTMRaki9-4AAAAAGGEES53ZWJydGM0cm9iaW9z',
									credential: '6b24cb7a-3d90-11ec-b36b-0242ac140004',
									urls: [
										'turn:us-turn8.xirsys.com:80?transport=udp',
										'turn:us-turn8.xirsys.com:3478?transport=udp',
										'turn:us-turn8.xirsys.com:80?transport=tcp',
										'turn:us-turn8.xirsys.com:3478?transport=tcp',
										'turns:us-turn8.xirsys.com:443?transport=tcp',
										'turns:us-turn8.xirsys.com:5349?transport=tcp',
									],
								},
							],
						},
					});
				} catch (error) {
					sentryLogger.error(`Error while initializing peer: ${(error as Error).message}`);
					sentryLogger.info('Restarting in 5 seconds');
					setTimeout(() => {
						hangup();
						window.location.reload();
					}, 5000);
				}

				try {
					socket = io(serverUrl, { secure: true, reconnection: true, rejectUnauthorized: false });
				} catch (error) {
					sentryLogger.error(`Error while initializing socket: ${(error as Error).message}`);
					sentryLogger.info('Restarting in 5 seconds');
					setTimeout(() => {
						hangup();
						window.location.reload();
					}, 5000);
				}
				myPeer.on('open', (id) => handlePeerOpen(id));
				myPeer.on('call', (call) => handlePeerIncomingCall(call));
				myPeer.on('error', (err) => handlePeerError(err));

				socket.on('connect', () => sentryLogger.info(`Socket connected`));
				socket.on('connect_error', (err) => handleSocketConnectError(err));
				socket.on('disconnect', () => handleDisconnection());
				socket.io.on('reconnect', () => handleReconnection());
				socket.io.on('reconnect_error', (err) => sentryLogger.error(`Reconnect socket error: ${err}`));

				socket.on('user-connected', (userId) => callPeer(userId));
				socket.on('user-disconnected', (userId) => disconnectPeer(userId));
			}

			function handlePeerOpen(id: string) {
				socket.emit('join-room', roomParameters.roomId, id);
				sentryLogger.info(`New User for room ${roomParameters.roomId}, platform=${window.navigator.userAgent}`);
				sentryLogger.info(`current user id is ${myPeer.id}`);
			}

			function handlePeerIncomingCall(call: Peer.MediaConnection) {
				sentryLogger.info(`A remote user with id ${call.peer} is calling`);
				call.answer(myStream);
			}

			function handlePeerError(err: Error) {
				sentryLogger.error(`Peer error: ${err.message}`);
				hangup();
				document.location.reload();
			}

			function handleSocketConnectError(err: Error) {
				sentryLogger.error(`Socket connect error: ${err.message}`);
				hangup();
				document.location.reload();
			}

			function handleDisconnection() {
				sentryLogger.info('Socket disconnected, check the internet connection');
				myPeer?.disconnect();
			}

			function handleReconnection() {
				sentryLogger.info('Reconnecting stream');
				hangup();
				document.location.reload();
			}

			function callPeer(userId: string) {
				sentryLogger.info(`Calling peer ${userId}`);
				const call = myPeer.call(userId, myStream, { metadata: 'robot-stream' });
				call.on('close', () => sentryLogger.debug(`Closing user ${userId} connection`));
				peers[userId] = call;
			}

			function disconnectPeer(userId: string) {
				sentryLogger.info(`User disconnected : ${userId}`);
				if (userId && peers[userId]) {
					peers[userId]?.close();
					delete peers[userId];
				}
			}

			function hangup() {
				sentryLogger.debug('Hangup event called, stopping meeting.');
				myStream?.getTracks()?.forEach((track: MediaStreamTrack) => track?.stop());
				myPeer?.destroy();
				socket?.removeAllListeners();
				socket?.disconnect();
			}

			return {
				hangup,
			};
		},
		beforeRouteLeave(to, from, next) {
			this.hangup();
			next();
		},
	});
